.toy {
  position: absolute;
  margin: auto;
  z-index: 180;
  background-image: url('../images/toys.png');
  background-repeat: no-repeat;
  background-size: 300% 300%;
  height: 0;
  will-change: transform;

  @mixin toy-item($top, $left, $pos, $size) {
    width: $size;
    padding-bottom: $size;
    top: $top;
    left: $left;
    background-position: $pos;
  }

  &-star { @include toy-item( -8%, 14%, 0 0, 15% ); }
  &-chip { @include toy-item( -2%, 76%, 50% 0, 20% ); }
  &-dog { @include toy-item( 70%, 76%, 100% 0, 34% ); }
  &-horseshoe { @include toy-item( 86%, 36%, 0 50%, 14% ); }
  &-mexico { @include toy-item( 38%, 94%, 100% 50%, 20% ); }
  &-leprechaun { @include toy-item( 56%, -16%, 0 100%, 46% ); }
  &-heifer { @include toy-item( 10%, -10%, 50% 100%, 24% ); }
  &-coin-one { @include toy-item( -5%, 80%, 50% 50%, 40% ); }
  &-coin-two { @include toy-item( 84%, 85%, 50% 50%, 26% ); }
  &-coin-three { @include toy-item( 84%, -5%, 50% 50%, 20% ); }

  @media (width >= 1200px) {
    &-star { @include toy-item( -8%, 10%, 0 0, 15% ); }
    &-chip { @include toy-item( -16%, 68%, 50% 0, 20% ); }
    &-horseshoe { @include toy-item( 92%, 36%, 0 50%, 14% ); }
    &-leprechaun { @include toy-item( 70%, -10%, 0 100%, 30% ); }
    &-heifer { @include toy-item( 8%, -12%, 50% 100%, 24% ); }
    &-coin-one { @include toy-item( -6%, 92%, 50% 50%, 16% ); }
    &-coin-two { @include toy-item( 84%, 90%, 50% 50%, 16% ); }
    &-coin-three { @include toy-item( 84%, -3%, 50% 50%, 9% ); }
  }
}
