:root {
  // base colors
  --c-blue: hsl(234deg 97% 48%);
  --c-bright-violet: hsl(304deg 99% 40%);
  --c-canary-yellow: hsl(60deg 100% 84%);
  --c-black: Black;
  --c-text-white: WhiteSmoke;
  --c-text-greenish-yellow: hsl(50deg 100% 59%);

  // z-orders
  --z-wheel-drum: 20;
  --z-wheel-blackout: 30;
  --z-wheel-blur: 40;
  --z-wheel-frame: 50;
  --z-wheel-button: 60;
  --z-modal: 200;

  // fonts
  --min-fs: .85;
  --max-fs: 1.3;
  --min-vw: 100;
  --max-vw: 150;
  --font-family-default: "Montserrat";
  --default-line-height: 1.5;
  --heading-line-height: 1.3em;

  // meta
  --meta-theme-color: var(--c-blue-royal);

  // scrollbar
  --c-scrollbar-track: var(--c-background-secondary);
  --c-scrollbar-thumb: var(--c-smoky-white);
  --c-scrollbar-hover: var(--c-smoky-white);
}
