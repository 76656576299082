.pay {
  display: grid;
  grid-template: repeat(2, auto) / repeat(3, auto);
  gap: 2em 3em;
  max-width: 560px;
  margin: auto;

  @media (width >= 1200px) {
    display: grid;
    grid-template-columns: repeat(5, auto);
    gap: 0 4em;
    width: fit-content;
    max-width: none;
    margin: unset;
  }

  &__item {
    display: grid;
    place-content: center;
  }

  .svg-icon {
    width: 100%;
    height: 100%;
    max-width: 7em;
    aspect-ratio: 100 / 54;
    fill: var(--c-text-white);

    @media (width >= 1200px) {
      width: 5em;
      height: 2.7em;
      max-width: none;
    }
  }
}
