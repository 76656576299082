.logotype {
  display: flex;
  //flex-direction: row;
  align-items: center;
  //gap: 0 1.75em;
  //width: fit-content;
  flex-direction: column;
  gap: 0.8em 0;

  //@media (width >= 1200px) {
  //  flex-direction: column;
  //  gap: 0.8em 0;
  //}

  @media (width >= 1200px) {
    gap: 1.4em 0;
  }

  &__star {
    &-svg {
      width: 3em;
      height: 3em;

      @media (width >= 1200px) {
        width: 8em;
        height: 8em;
      }
    }
  }

  &__champion {
    &-svg {
      width: 10em;
      height: 3em;
      fill: var(--c-text-white);

      @media (width >= 1200px) {
        width: 18em;
        height: 5.4em;
      }
    }
  }
}
