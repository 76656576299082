.btn-bonus {
  position: relative;
  display: grid;
  place-content: center;

  span {
    text-align: center;
    color: var(--c-text-white);
    font-weight: 900;
    background-image: linear-gradient(65deg, #f35626, #feab3a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: hue 2.5s infinite alternate ease-in-out;
    transform: scale(1, 1.2);
    font-size: 3.2em;

    @media (width >= 1200px) {
      font-size: 3.6em;
    }

    @-webkit-keyframes hue {
      from {
        transform: rotate(-2deg) scale(1, 1.4);
        filter: hue-rotate(180deg) brightness(1.5);
      }

      to {
        transform: rotate(2deg) scale(1, 1.4);
        filter: hue-rotate(340deg) brightness(1.5);
      }
    }
  }
}

.btn-pick {
  pointer-events: auto;
  margin-top: 1em;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2);
  display: grid;
  place-content: center;
  width: 8.2em;
  height: 2.4em;
  line-height: 1;
  background: linear-gradient(0.25turn, #FFE03F, #FF9B3F);
  color: var(--c-text-white);
  border-radius: 0.8em;
  font-weight: 860;
  font-size: 2.6em;
  text-transform: uppercase;
  text-decoration: none;
}
