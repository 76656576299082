.title-main {
  margin: 0;
  font-size: 2.9em;
  font-weight: 860;
  font-style: italic;
  color: var(--c-text-white);
  max-inline-size: 20ch;
  text-align: center;

  @media (width >= 1200px) {
    font-size: 5.1em;
  }
}

.title-bonus {
  margin: 0;
  font-size: 2em;
  font-weight: 860;
  font-style: italic;
  color: var(--c-text-white);
  text-align: center;
  max-inline-size: none;
}
