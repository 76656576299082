.title-bonus {
  margin: 1.5em 0 -0.4em;
  transform: scale(0);
  opacity: 0;
}

.bonuses {
  position: relative;
  width: fit-content;
  height: 100%;
  margin: 1.8em auto 4em;

  &__stars {
    position: absolute;
    inset: 0;
    margin: auto;
    z-index: 3;

    &-svg {
      position: absolute;
      inset: 0;
      margin: auto;
      width: 12.8em;
      height: 12.8em;
      fill: var(--c-text-white);
    }

    .bonus-star-one {

    }

    .bonus-star-two {

    }
  }

  &__buttons {
    position: relative;
    display: grid;
    grid-template: 1fr / auto fit-content(var(--hidden-width, 0%)) fit-content(var(--hidden-width, 0%));
    gap: 0;

    white-space: nowrap;
    transform: scale(0);

    .bonus-btn-one {

    }

    .bonus-btn-separator {
      align-self: center;

      color: var(--c-text-white);
      font-size: 2.2em;
      line-height: 1;

      font-weight: 900;
      overflow: hidden;
    }

    .bonus-btn-two {
      overflow: hidden;
    }
  }
}
