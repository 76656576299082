@mixin pos-wheel-children {
  position: absolute;
  inset: 8.8%;
  margin: auto;
  border-radius: 100%;
}

.wheel {
  position: relative;
  margin: auto;
  max-width: min(80%, 520px);
  max-height: 80vh;
  aspect-ratio: 1;
  border-radius: 100%;
  background: url('../images/wheel-frame.png') center / contain no-repeat;
  box-shadow: rgba(0, 0, 0, 0.4) 0 2em 5em;

  @media (width >= 1200px) {
    max-width: min(90%, 680px);
    box-shadow: rgba(0, 0, 0, 0.4) 0 2.5em 7em;
  }

  &__drum {
    @include pos-wheel-children;

    overflow: hidden;
    z-index: var(--z-wheel-drum);
    border-radius: 100%;
    will-change: transform;
    background-image: url('../images/wheel-drum.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &-svg {
      width: 100%;
      height: 100%;
    }
  }

  &__blackout {
    @include pos-wheel-children;

    z-index: var(--z-wheel-blackout);
    opacity: 0;
    fill: var(--c-black);
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0NDAiIGhlaWdodD0iNDQwIiBmaWxsPSJub25lIj48cGF0aCBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMDkuNjI1IDMyLjk2NiAyMjAuMDAyIDIyNSAzMzAuMzc0IDMyLjk2NkMzOTQuNzc2IDcwLjg1IDQzOCAxNDAuODc4IDQzOCAyMjFjMCAxMjAuMzk4LTk3LjYwMiAyMTgtMjE4IDIxOFMyIDM0MS4zOTggMiAyMjFDMiAxNDAuODc4IDQ1LjIyNCA3MC44NTEgMTA5LjYyNSAzMi45NjZaIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  &__blur {
    @include pos-wheel-children;
    will-change: transform;

    z-index: var(--z-wheel-blur);
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    pointer-events: none;
  }

  &__frame {
    position: absolute;
    inset: 9%;
    margin: auto;
    z-index: var(--z-wheel-frame);
    border-radius: 100%;
    box-shadow: 0 0 0 0.6vmin var(--c-canary-yellow);
  }

  &__button {
    position: absolute;
    inset: 0;
    margin: auto;
    z-index: var(--z-wheel-button);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0;
    width: max(31%, 84px);
    height: max(31%, 84px);
    border-radius: 100%;
    background-color: #3C0078;
    background-image: linear-gradient(-0.05turn, #3C0078, #6F1EF3);
    box-shadow:
            0 0 0 0.4em var(--c-canary-yellow),
            inset 0.6em 0.6em 1.2em hsla(0 0% 100% / 0.4),
            inset -0.6em -0.6em 1.2em hsla(0 0% 0% / 0.3),
            inset 0.6em 0.6em 0.8em hsla(0 0% 100% / 0.1),
            inset -0.6em -0.6em 0.8em hsla(0 0% 0% / 0.2);
    padding: 0.8em 0.4em 0.4em;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: box-shadow 75ms ease;

    .svg-icon {
      transform: translateY(50%);
      transition: transform 375ms ease-out, opacity 75ms ease-out;
    }

    &.spinning,
    &.second-spin-end {
      pointer-events: none;

      .svg-icon {
        opacity: 0.45;
      }
    }

    &.first-spin-end {
      .svg-icon {
        transform: translateY(0);
      }
    }

    &:not(.first-spin-end) {
      .svg-icon:last-child {
        opacity: 0;
      }
    }

    @media (width >= 1200px) {
      width: max(26%, 84px);
      height: max(26%, 84px);
    }

    &-spin {
      pointer-events: none;
      width: 76%;
      height: 18%;
    }

    &-more {
      pointer-events: none;
      width: 76%;
      height: 18%;
    }

    &:not(.spinning)::after {
      position: absolute;
      inset: 0;
      margin: auto;
      z-index: 999;
      width: 100%;
      height: 100%;
      content: '';
      box-shadow:
              -0.5em 0.7em 0.9em 0.4em rgba(255, 255, 0, 0.65),
              -0.7em -0.7em 0.9em 0.4em rgba(0, 255, 255, 0.65);
      border-radius: 100%;
      animation: spin 2.6s linear infinite reverse;

      @keyframes spin {
        to {
          transform: rotate(1turn);
        }
      }
    }

    &:not(.spinning)::before {
      position: absolute;
      inset: 0;
      margin: auto;
      z-index: 999;
      width: 100%;
      height: 100%;
      content: '';
      box-shadow:
              0.7em -0.9em 0.9em 0 rgba(255, 255, 255, 0.8),
              -0.7em -0.7em 0.9em 0 rgba(255, 0, 255, 0.65);
      border-radius: 100%;
      animation: spin 2.2s linear infinite;

      @keyframes spin {
        to {
          transform: rotate(1turn);
        }
      }
    }
  }

  &__toys {
    position: absolute;
    inset: 0;
    z-index: 180;
    pointer-events: none;
  }
}
